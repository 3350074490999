import axios from 'axios';
import history from "../utils/history";

export const API_URL = "https://api.groupe-keran.com/api";


export function getExternalData(errorType, url, dispatch ) {
  return new Promise(function(resolve, reject) {  
    let config = {
      headers: { 
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/x-www-form-urlencoded",
        "Access-Control-Allow-Methods": "GET, POST, OPTIONS, HEAD",
        "Access-Control-Allow-Headers": "Access-Control-Allow-Origin , Origin, X-Requested-With, Content-Type, Accept"
      }
    };
    console.log(config)
    axios.get(url)
    .then((response) => {
      // console.log(response)
      resolve(response.data)
    })
    .catch((error) => {
      reject()
      errorHandler(dispatch, error, errorType);
    });

  });

}

export function getData(errorType, url, dispatch, isAuthReq = false) {
  return new Promise(function(resolve, reject) {

    const requestUrl = API_URL + url;
    
    let token = localStorage.getItem('token');

    let config = {
        headers: {
          Authorization: token
        }
    };

    axios.get(requestUrl, isAuthReq ? config : null )
    .then((response) => {
      resolve(response.data)
    })
    .catch((error) => {
      reject()
      errorHandler(dispatch, error, errorType);
    });

  });

}

// export function getFile(errorType, url, dispatch, isAuthReq = false) {
//   return new Promise(function(resolve, reject) {

//     const requestUrl = API_URL + url;
//     let token = localStorage.getItem('token');

//     let config = {
//       responseType: 'blob',
//       headers: {
//         Authorization: token
//       }
//     };

//     axios.get(requestUrl, isAuthReq ? config : null )
//     .then((response) => {
//       resolve(response.data)
//     })
//     .catch((error) => {
//       reject()
//       errorHandler(dispatch, error, errorType);
//     });

//   });

// }

export function deleteData(errorType, url, dispatch, isAuthReq = true ) {
  return new Promise(function(resolve, reject) {

    const requestUrl = API_URL + url;
    let token = localStorage.getItem('token');

    let config = {
       headers: {
          Authorization: token
        }
    };

    axios.delete(requestUrl, isAuthReq ? config : null )
    .then((response) => {
      resolve(response.data)
    })
    .catch((error) => {
      reject()
      errorHandler(dispatch, error, errorType);
    });

  });

}


export function putData(action, errorType,  url, dispatch, data , isAuthReq = false) {
  return new Promise(function(resolve, reject) {

    const requestUrl = API_URL + url;
    let token = localStorage.getItem('token');

    let config = {
       headers: {
          Authorization: token
        }
    };

    axios.put(requestUrl, data, isAuthReq ? config : null )
    .then((response) => {
      resolve(response.data)
    })
    .catch((error) => {
      reject(action)
      errorHandler(dispatch, error, errorType);
    });

  })
}

export function postData(action, errorType,  url, dispatch, data , isAuthReq = false) {
  return new Promise(function(resolve, reject) {

    const requestUrl = API_URL + url;

    var config = {
      timeout: 1000 * 60
    };

    let token = localStorage.getItem('token');

    if (isAuthReq) {
      config = {
        headers: {
           Authorization: token
         }
      }
    }

    axios.post(requestUrl, data, config)
    .then((response) => {
      resolve(response.data)
    })
    .catch((error) => {
      reject(action)
      errorHandler(dispatch, error, errorType);
    });

  })
}

export function postFiles(action, errorType, url, dispatch, data , isAuthReq = false) {
  return new Promise(function(resolve, reject) {
    const requestUrl = API_URL + url;

    var config = {
    };

    let token = localStorage.getItem('token');

    if (isAuthReq) {
      config = {
        headers: {
        Authorization : token,
        'Content-Type' : 'multipart/form-data'
        }
      };
    };

    // const config = {
    //   onUploadProgress: function(progressEvent) {
    //     var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    //     var progress = {
    //       progress: percentCompleted,
    //       fileState: "progress",
    //       _id: info._id,
    //       name: info.name
    //     }

    //     dispatch({
    //       type: MESSAGE_FILES,
    //       payload: progress,
    //     });
    //   }
    // }

    axios.post(requestUrl, data, config)
    .then((response) => {
      resolve(response.data)
    })
    .catch((error) => {
      reject(action)
      errorHandler(dispatch, error, errorType);
    });
  });
}

export function errorHandler(dispatch, error, type) {
  console.log('Error type: ', type);
  console.log(error.response)
  
  if(error.response  && error.response.status === 401 ){

    if (type !== 'ERROR_AUTH') {
      // dispatch({
      //   type: 'SET_TOAST',
      //   payload: {
      //     message: "Vous n’êtes pas autorisé à accéder à cette page.",
      //     type: "error"
      //   }
      // });
      history.push('/')
    }

    if (localStorage.getItem('token')) {
      localStorage.removeItem('token')
    }
    dispatch({
      type: 'IS_AUTH',
      payload: false,
    });

    dispatch({
      type: type,
      payload: error.response,
    });
  } else {
    dispatch({
      type: type,
      payload: error.response,
    });
  }
}
