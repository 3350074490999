import React , { useEffect , useCallback , useState , useRef } from 'react';
import { useParams } from 'react-router-dom';
import Select from 'react-select';

import { useGlobalContext } from '../../context/GlobalContext';

import MediaCard from './MediaCard';
import Modale from '../../partials/Modale';
import AddMedia from './AddMedia';
import RemoveModale from '../../partials/RemoveModale/RemoveModale'; 
import { companiesColors } from '../../../utils/utils';

import { getCompanyAction } from '../../../actions/companiesActions';
import { getMediasAction } from '../../../actions/mediasActions';

import './medias.scss';
import EditMedia from './EditMedia';

export default function MediasPage(props) {
  const customStyles = {
    multiValue: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? companiesColors[theme] : companiesColors[theme] 
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      color: 'white'
    }),
    container: (provided, state) => ({
      ...provided,
      width: '100%',
      marginTop:7,
      marginBottom:20,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      padding: "0px 8px",
      fontSize: 14
    }),
    control: (provided, state) => ({
      ...provided,
      boxShadow: "none",
      minHeight: 44,
      borderRadius: 10,
      border: state.isFocused || state.isSelected ? "1px solid " + companiesColors[theme] : "1px solid #E0E3EF"
    })
  }

  const [ context, dispatch ] = useGlobalContext();
  const param = useParams();
  const containerRef = useRef();

  const { setDisplayNavBar , setActivePage , theme , setDisplaySiteNav, lang } = props;
  const sortOptions = [
    { value: 'images' , label: 'Les images' },
    { value: 'vidéos' , label: 'Les vidéos' },
    { value: 'fichiers' , label: 'Les fichiers' },
    { value:'miniatures' ,  label: 'Les miniatures' },
  ]


  const [ mediaList , setMediaList ] = useState();
  const [ search , setSearch ] = useState();
  const [ videos , setVideos ] = useState();
  const [ selectedList , setSelectedList ] = useState(sortOptions[0]);
  const [ addMediaIsOpen , setAddMediaIsOpen ] = useState(false);
  const [ editMedia , setEditMedia ] = useState();
  const [ removeAction , setRemoveAction ] = useState();

  const getDatas = useCallback(() => {
    getCompanyAction(dispatch , param.companyId );
    getMediasAction(dispatch);
  },[dispatch , param.companyId ]);

  useEffect(() => {
    setDisplayNavBar(true);
    setDisplaySiteNav(false);
    setActivePage("medias")
    getDatas();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (search && context.medias.mediasList) {
      var newList = context.medias.mediasList.filter(item => item.company === param.companyId ).filter(media => media.title.toLowerCase().includes(search.toLowerCase())  || media.description.toLowerCase().includes(search.toLowerCase()) );
      setMediaList(newList)
    } else if (!search && context.medias.mediasList) {
      setMediaList(context.medias.mediasList.filter(item => item.company === param.companyId ))
    }
    // eslint-disable-next-line
  }, [search , context.medias.mediasList , param.companyId ])

  useEffect(() => {
    if (mediaList) {
      setVideos(mediaList.filter(media => media.extension === "mp4"));
    }
    // eslint-disable-next-line
  },[mediaList]);


  function renderMediaList() {

    if(mediaList) {

      return (
        <div className="category">
          {selectedList.value === "images" &&
            <>
              <div id={"images"} className="title">
                <h2>Les images</h2>
              </div>
              <div className="media-list">
                {mediaList.filter(item => !item.isThumbnail).filter(media => media.extension === "jpeg" || media.extension === "png" || media.extension === "svg" || media.extension === "svg+xml" ).map((media , i ) => (<MediaCard key={"image" + i} theme={theme} media={media}  setEditMedia={setEditMedia} setRemoveAction={setRemoveAction} dispatch={dispatch}/>))}
              </div>
            </>
          }
          {selectedList.value === "vidéos" &&
            <>
              <div id={"vidéos"} className="title">
                <h2>Les vidéos</h2>
              </div>
              <div className="media-list">
                {videos && videos.map((media , i ) => (<MediaCard key={"video" + i} media={media} theme={theme} setEditMedia={setEditMedia} setRemoveAction={setRemoveAction} dispatch={dispatch}/>))}
              </div>
            </>
          }
          {selectedList.value === "fichiers" &&
            <>
            <div id={"fichiers"} className="title">
              <h2>Les fichers</h2>
            </div>
            <div className="media-list">
              {mediaList.filter(media => media.extension === "pdf" || media.extension === "xls" || media.extension === "xlsx"  ).map((media , i ) => (<MediaCard key={"document" + i} theme={theme} media={media} setEditMedia={setEditMedia} setRemoveAction={setRemoveAction} dispatch={dispatch}/>))}
            </div>
            </>
          }
          {selectedList.value === "miniatures" &&
            <>
            <div id={"miniatures"}  className="title">
              <h2>Les miniatures</h2>
            </div>
            <div className="media-list">
              {mediaList.filter(item => item.isThumbnail).filter(media => media.extension === "jpeg" || media.extension === "png" || media.extension === "svg" || media.extension === "svg+xml" ).map((media , i ) => (<MediaCard key={"thumbnail" + i} media={media} theme={theme} setEditMedia={setEditMedia} setRemoveAction={setRemoveAction} dispatch={dispatch}/>))}
            </div>
            </>
          }
        </div>
      )
    }
  }

  return (
    <>
      <div className={`page-container ${theme}`}>
      <div className="page-content">

        <div className="title-row space-between">

          <div className="title">
            <h1>Médiathèque</h1>
            <div className="bar primary"></div>
          </div>

          <input
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Recherche"
            />
          
          <div className="select">
            <Select
              styles={customStyles}
              value={selectedList}
              isSearchable={false}
              options={sortOptions}
              placeholder={''}
              onChange={(val) => setSelectedList(val)}
            />
          </div>
  

          <button
            onClick={() => setAddMediaIsOpen(true)}
            className="btn primary">
            Ajouter un fichier
          </button>
        </div>

        <div ref={containerRef} className="media-list">
          {renderMediaList()}
        </div>

        </div>
      </div>
      <RemoveModale removeAction={removeAction} setRemoveAction={setRemoveAction} theme={theme}/> 
      <Modale
        modaleIsOpen={addMediaIsOpen}
        modaleToggle={setAddMediaIsOpen}
        className={"add-media-modale"}
        >
        {addMediaIsOpen && <AddMedia
          theme={theme}
          lang={lang}
          type=""
          setAddMediaIsOpen={setAddMediaIsOpen}
          companyId={param.companyId}
          />
        }
      </Modale>
      <Modale
        modaleIsOpen={editMedia}
        modaleToggle={() => setEditMedia()}
        className={"add-media-modale"}
        >
        {editMedia && <EditMedia
          theme={theme}
          lang={lang}
          media={editMedia}
          setEditMedia={setEditMedia}
          companyId={param.companyId}
          type=""
          />
        }
      </Modale>
    </>
  )
}
